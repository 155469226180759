import { Vue, Options } from 'vue-class-component';
import { modalController } from '@ionic/vue';
import IconTimes from '@/views/components/icons/IconTimes.vue';
import passwordClient from '@/core/api/password-service';
import { RecoveryPassword } from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'RecoverPassword',
    components: {
        IconTimes,
    },
})
export default class RecoverPassword extends Vue {
    dismiss() {
        modalController.dismiss();
    }
    async submitRecover(data: any) {
        try {
            await passwordClient.recovery(RecoveryPassword.fromJS({ email: data.email }));
            modalController.dismiss({ recoverSent: true });
        } catch (e) {
            modalController.dismiss({ recoverSent: false });
        }
    }
}
