<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons>
                <ion-button
                    color="danger"
                    class="button-close ml-auto"
                    @click="dismiss"
                >
                    <IconTimes class="text-black" />
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding text-center" color="light" fullscreen>
        <div class="d-flex align-items-stretch ml-2 text-black">
            <h4>
                {{ $t("recoverPassword.title") }}
            </h4>
        </div>

        <ion-card class="recover-pwd-card">
            <ion-card-content
                ><VForm @submit="submitRecover">
                    <p v-html="$t('recoverPassword.description')"></p>
                    <ion-item
                        class="ion-no-padding text-center mt-3"
                        color="light"
                    >
                        <VField
                            name="email"
                            v-slot="{ field }"
                            rules="required|email"
                        >
                            <ion-input
                                type="email"
                                :placeholder="$t('emailAddress')"
                                v-bind="field"
                                name="email"
                            ></ion-input>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="email" />
                    <p class="mt-3" v-html="$t('recoverPassword.contacts')"></p>
                    <p class="fw-bold" v-html="$t('recoverPassword.email')"></p>
                    <div class="justify-content-center mt-3">
                        <ion-button
                            fill="clear"
                            class="text-black"
                            @click="dismiss"
                        >
                            {{ $t("cancel") }}
                        </ion-button>
                        <ion-button color="primary" type="submit">
                            {{ $t("proceed") }}
                        </ion-button>
                    </div>
                </VForm>
            </ion-card-content>
        </ion-card>
    </ion-content>
</template>

<script lang="ts" src="./RecoverPassword.ts"></script>
<style lang="scss" src="./RecoverPassword.scss"></style>
