import { Vue, Options } from 'vue-class-component';
import { Login as LoginDTO } from '@/../client-generator/generated-clients/api-clients';
import authClient from '@/core/api/auth-service';
import { AUTHENTICATE } from '@/core/store/types/action-types';
import { modalController, IonBadge, IonIcon } from '@ionic/vue';
import RecoverPassword from './RecoverPassword/RecoverPassword.vue';
import { message } from '@/core/utilities/utilities';
import IconZupit from '@/views/components/icons/IconZupit.vue';
import config from '@/core/config/config';
import { helpCircle as iconHelp } from 'ionicons/icons';

@Options({
    name: 'Login',
    components: {
        IconZupit,
        IonBadge,
        IonIcon,
    },
})
export default class Login extends Vue {
    message = message;
    wrongUsernameOrPassword = false;
    privacyPolicyUrl = process.env.VUE_APP_PRIVACY_POLICY_URL;
    recoverPasswordSent = false;

    helpUrl = config.helpUrl;
    iconHelp = iconHelp;

    async login(data: any) {
        this.wrongUsernameOrPassword = false;

        try {
            const loginDTO = new LoginDTO({ username: data.username, password: data.password });
            const result = await authClient.token(loginDTO);
            this.$store.dispatch(AUTHENTICATE, { token: result.access, refreshToken: result.refresh, authState: true });
            this.$router.replace({
                name: 'page-relatives-list',
            });
        } catch (error) {
            switch (error.status) {
                case 401:
                    this.wrongUsernameOrPassword = true;
                    break;
                default:
                    this.message(this.$t('apiError'));
                    break;
            }
        }
    }

    async resetPassword() {
        const modal = await modalController.create({
            component: RecoverPassword,
        });
        await modal.present();
        const modalResponse = await modal.onDidDismiss();
        if (modalResponse.data && modalResponse.data.recoverSent) {
            this.recoverPasswordSent = true;
        }
    }
}
