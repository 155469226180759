<template>
    <ion-page>
        <ion-content padding class="page-login">
            <div
                class="page-login__container d-flex flex-column ion-justify-content-center"
            >
                <div class="help-bar">
                    <a :href="helpUrl" target="_system" class="top-help-button">
                        <ion-icon :icon="iconHelp" />
                        {{ $t("help") }}
                    </a>
                </div>
                <div>
                    <ion-card>
                        <ion-card-content class="login-card-content">
                            <VForm @submit="login">
                                <h1 class="mb-0 text-black">
                                    {{ $t("login") }}
                                </h1>
                                <div
                                    class="alert alert-danger"
                                    role="alert"
                                    v-if="wrongUsernameOrPassword"
                                >
                                    {{ $t("usernameOrPasswordWrong") }}
                                </div>
                                <div
                                    class="alert alert-success"
                                    role="alert"
                                    v-if="recoverPasswordSent"
                                >
                                    {{ $t("checkYourInbox") }}
                                </div>
                                <ion-item class="ion-no-padding">
                                    <ion-label
                                        position="stacked"
                                        class="fw-bold mb-2"
                                        >{{ $t("username") }}</ion-label
                                    >
                                    <VField
                                        name="username"
                                        v-slot="{ field }"
                                        rules="required"
                                    >
                                        <ion-input
                                            type="email"
                                            :placeholder="$t('username')"
                                            v-bind="field"
                                            name="username"
                                        ></ion-input>
                                    </VField>
                                </ion-item>
                                <VErrorMessage name="username" />

                                <ion-item class="ion-no-padding mt-2">
                                    <ion-label
                                        position="stacked"
                                        class="fw-bold mb-2"
                                        >{{ $t("password") }}</ion-label
                                    >
                                    <VField
                                        name="password"
                                        v-slot="{ field }"
                                        rules="required"
                                    >
                                        <ion-input
                                            type="password"
                                            :placeholder="$t('password')"
                                            v-bind="field"
                                            name="password"
                                        ></ion-input>
                                    </VField>
                                </ion-item>
                                <VErrorMessage name="password" />
                                <div class="col text-right">
                                    <a
                                        href="#"
                                        @click.prevent="resetPassword"
                                        >{{ $t("forgotPassword") }}</a
                                    >
                                </div>

                                <ion-button
                                    color="primary"
                                    expand="block"
                                    class="mx-0 mt-4"
                                    type="submit"
                                >
                                    {{ $t("enter") }}
                                </ion-button>

                                <div class="mt-3 row">
                                    <div class="col text-center">
                                        {{ $t("notRegistered") }}?
                                        <router-link
                                            :to="{ name: 'page-register' }"
                                            >{{ $t("requestActivation") }}
                                        </router-link>
                                    </div>
                                </div>
                            </VForm>
                            <div class="login-info text-black">
                                <ion-badge color="secondary">
                                    <span
                                        class="text-uppercase"
                                        v-html="$t('whatIsVicino')"
                                    ></span>
                                </ion-badge>
                                <i18n
                                    keypath="loginText"
                                    tag="div"
                                    class="mt-2"
                                >
                                    <br />
                                    <br />
                                    <span>Vicino@TE</span>
                                </i18n>
                            </div>
                        </ion-card-content>
                    </ion-card>
                    <div class="d-flex bottom-container mx-4 mb-4">
                        <a :href="privacyPolicyUrl" target="_system">{{
                            $t("privacyPolicy")
                        }}</a>
                        <div class="ml-auto bottom-container__credits">
                            <a href="https://bit.ly/2WKwAuK" target="_system">
                                <em>{{ $t("developedBy") }}</em>
                                <strong class="ml-1">{{ $t("zupit") }}</strong>
                                <IconZupit
                                    class="bottom-container__zupit-icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Login.ts"></script>
<style lang="scss" src="./Login.scss"></style>
